import React from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 400px;
`;

const StyledCopy = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 1000px;
  margin: 0 auto;
  max-width: 80%;
`;

const StyledHeading = styled.div`
  text-align: center;
  padding: 4rem 4rem 4rem 4rem;
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 4rem 18rem;

  @media screen and (max-width: 1188px) {
    max-width: 500px;
    padding: 2rem;
  }
`;

const StyledSectionHeading = styled.div`
  text-align: center;
`;

const Meditation = () => {
  return (
    <StyledCopy>
      <StyledHeading>
        <h1>Mindfulness meditation</h1>
        <h1>Tips for practice</h1>
        <StyledImage
          src="https://blue-heron-public-images.s3.amazonaws.com/BHS_calligraphy.jpg"
          alt="BHS logo"
        />
      </StyledHeading>
      <StyledSection>
        <StyledSectionHeading>
          <h2>Entering the meditation space</h2>
          <h3>"I have arrived, I am home"</h3>
        </StyledSectionHeading>
        <p>
          <b>What happens during the meditation session?</b>
        </p>
        <p>
          Thich Nhat Hanh sanghas follow a similar meditation format for their meetings.
          There is an invocation, silent sitting meditation, walking meditation,
          guided meditation, singing, an activity, dharma sharing, and announcements.
          These elements are discussed in more detail below.
        </p>
        <p>
          <b>Why do we bow when entering the meditation room?</b>
        </p>
        <p>
          The bow is a pause, a deliberate reminder that we are making a
          transition from the world of distraction to a space of calm and quiet.
          The bow is an embodiment of slowing down as we enter a sacred space,
          though it is not a requirement.
        </p>
        <p>
          <b>Why don’t we talk at the beginning?</b>
        </p>
        <p>
          Silence helps us withdraw from the multiple stimuli we receive daily.
          Noticing thoughts, emotions and feelings is easier when we aren’t
          talking. Silence is not punitive, though you may feel odd if you are
          new to silence. There is time at the end of each session for
          socializing and mindful conversation.
        </p>
        <p>
          <b>What’s the best way to sit?</b>
        </p>
        <p>
          Notice your posture. Lengthen your spine. This allows your breath to
          flow deeply and smoothly. If you sit on a chair, sit away from the
          backrest and keep your feet flat on the floor. Keep your legs parallel
          with a 90 degree right angle at the ankles, knees, and hips. Use a
          foot bolster if needed. On the floor, bench, or cushion, sit so that
          your knees can open outward and be lower than your hips. Use two
          cushions if needed. (Meditation may also be done while standing up,
          walking or lying down.) Except when walking, keep your eyes closed or
          partly closed to reduce distractions. You don’t get extra points for
          enduring pain while sitting. If your body needs to change position,
          you can do so quietly.
        </p>
      </StyledSection>
      <StyledSection>
        <StyledSectionHeading>
          <h2>Beginning your meditation session</h2>
          <h3>"The sound of the bell"</h3>
        </StyledSectionHeading>
        <p>
          <b>What is the Bell Master?</b>
        </p>
        <p>
          The Bell Master is a sangha member who facilitates for the evening. The
          session begins with welcoming words and a contemplative reading. The
          Bell Master leads opening and closing verses, offers the prompts for
          moving between activities, selects the texts for guided and walking
          meditations, and ensures that the session ends on time. Anyone can
          learn to be the Bell Master – volunteers are welcome!
        </p>
        <p>
          <b>What are the Bells used for?</b>
        </p>
        <p>
          Bell sounds remind us to come back to our breathing, to signal a
          transition between activities, and to create a sense of reverence and
          deep listening as their tones resonate. The largest
          bell is for beginning/ending an activity; the middle bell is for
          moving the body after a period of sitting; the chimes prompt us to
          stand up. The Bell Master offers a bow before inviting the bell as a
          gesture of respect and gratitude for the elements and people that
          produced the bell and acknowledgement of the bell’s role reminding us
          to come to our senses.
        </p>
        <p>
          <b>What does the Bell Master sing at the beginning?</b>
        </p>
        <p>
          The opening chant invites us to sit with solidity, to calm our
          thinking, to bring minds and hearts fully into meditation, to see our
          inherent nature of goodness. The call and response segment offers our
          respect to the historical Buddha for attaining enlightenment and
          transmitting his teachings to us.
        </p>
        <p>
          <b>What does it mean to “set an intention”?</b>
        </p>
        <p>
          An intention can be an aspiration to follow your breath closely, or to
          walk with greater concentration, or to hold the cares of another
          person in your heart and mind, or to open your inner eye to gratitude,
          compassion, forgiveness, or to look deeply at a question. Setting an
          intention for your meditation is an optional, but useful, way to focus
          your attention.
        </p>
      </StyledSection>
      <StyledSection>
        <StyledSectionHeading>
          <h2>Finding stillness in meditation</h2>
          <h3>"Present moment, wonderful moment"</h3>
        </StyledSectionHeading>
        <p>
          <b>How do I meditate?</b>
        </p>
        <p>
          Focusing on the breath is the most common practice while meditating.
          Notice where the sensation of breathing is most obvious (nose, throat,
          chest, etc). Maintain your attention on that place. When you notice
          your mind has wandered away (it will!), come back to your breath. Over
          time, your mind will get more stable. You may also count your breaths,
          or use a different focus, such as a repeated word/phrase or a
          visualization. Experiment to find what works best for you. The silent
          sitting periods last about 20 minutes.
        </p>
        <p>
          <b>What is a guided meditation?</b>
        </p>
        <p>
          Mindfulness meditation is not limited to sitting in silence. The theme
          of the guided meditation explores an element of mindfulness practice,
          e.g. bodily sensations, connection with nature, kindness toward
          others; it offers key words we can take in while sitting in silence
          between the bells. The verses are separated by sounds of the bell. The
          guided meditation offers a contemplative focus. It lasts about 15
          minutes.
        </p>
        <p>
          <b>What Is Walking Meditation?</b>
        </p>
        <p>
          Walking meditation is a moving mindfulness practice. We walk very
          slowly and direct our full attention to the body: the weight shifting,
          feet lifting and reconnecting with the ground, legs moving forward,
          sounds of walking, and the breath. We coordinate our breathing with
          the movement of the feet. Keep the eyes cast down, but not closed.
          Hands can be clasped in front of the belly, held in a prayer pose, or
          they can hang at your sides. If walking is not comfortable for you,
          you may remain sitting or stand or lie down. The walking meditation
          segment is about 15 minutes.
        </p>
        <p>
          <b>Why do we bow?</b>
        </p>
        <p>
          To join our palms and lower our heads to another person is a form of
          greeting without words, an embodied way of slowing down and
          recognizing the innate goodness of another person. The bow says “I see
          you, I respect you,” and no words are needed. When the sangha bows
          together, it signifies recognition and respect for the precious nature
          of a community of mindful friends. A bow directed to the Bell, to the
          candle, to a cushion or another object is a practice of mindfulness,
          recognizing that we do not practice on our own, that we are
          interconnected.
        </p>
        <p>
          <b>Why do we sing songs?</b>
        </p>
        <p>
          Music activates a different part of the brain, and singing is a
          different way to learn. The practice songs we sing offer concise
          lessons about profound Buddhist teachings such as mindfulness (…in the
          here and in the now…), interconnection (…I am in you…), being present
          (…in, out, deep slow…) , appreciating Mother Earth (…blooming like a
          flower…).
        </p>
        <p>
          <b>What is a Dharma Activity?</b>
        </p>
        <p>
          The “Dharma Activity” supplements sitting and walking meditation, and
          like the songs, offers another avenue to deepen our understanding of
          mindfulness. On the second Tuesday each month the activity may be a
          recorded talk or a shared reading by TNH or another well known
          teacher. The focus will be on a topic like compassion, kindness, or
          meditation. On the first Tuesday each month the Dharma Activity is
          mindfulness of the body, and on the last Tuesday the theme is the
          Mindfulness Trainings.
        </p>
        <p>
          <b>What are Mindfulness Trainings?</b>
        </p>
        <p>
          These guidelines for living mindfully and ethically in the 21 st
          century flow from the ancient code of living handed down by the Buddha
          2500 years ago. They address speech, nonviolence, sexual behavior,
          generosity, and consumption. They are not commandments, rather they
          are like a map, or the Pole Star, to help us stay on the path of
          mindfulness and conscious living.
        </p>
        <p>
          <b>What is Dharma Sharing?</b>
        </p>
        <p>
          Each week includes time for hearing what others are learning about
          meditation and life. We look deeply within our own hearts and talk
          about a joy, a sorrow, a challenge, or an insight. We share from
          direct experience, and the community listens without interruption,
          judging or questioning. This is helpful both to the speaker and to the
          listeners…”dharma sharing nourishes collective wisdom.” If you wish to
          share (it is not mandatory), join palms and bow to the group to
          indicate readiness, and bow when you are finished speaking.
        </p>
      </StyledSection>
      <StyledSection>
      <StyledSectionHeading>
          <h2>Closing the Meditation Session</h2>
          <h3>"In the ultimate I dwell"</h3>
        </StyledSectionHeading>
        <p>
          <b>What is the closing chant?</b>
        </p>
        <p>
          The Bell Master ends the session with a call and response verse about
          dedicating merit, which refers to the positive energy generated during
          the practice. The closing verse sends that energy of peace, compassion
          and goodness out into the world, for the benefit of all beings.
        </p>
        <p>
          <b>What is dana?</b>
        </p>
        <p>
          There is no membership or entry fee to meditate. A voluntary monetary
          gift is collected, and used for the Sangha's Zoom account, building rent
          when we resume in-person meetings, the scholarship fund,
          the annual retreat, and local service projects. This donation is
          called “dana,” (a Sanskrit word pronounced donna), and placed in a
          bell called the “dana bell” when we meet in person. There is a mailing address
          to receive donations while we meet via Zoom.
        </p>
        <p>
          <b>What is included in announcements?</b>
        </p>
        <p>
          The last part of the evening is sharing information about upcoming
          mindfulness events, such as mindful walks, Days of Mindfulness,
          retreats and social gatherings. Announcements are good time to ask
          questions that may not be covered on this page!
        </p>
      </StyledSection>
    </StyledCopy>
  );
};

export default Meditation;
