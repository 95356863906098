import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledWelcome = styled.div`
  display: table;
  min-height: 1500px;
  margin-inline: 5% 5%;
  margin-block: 2% 5%;

  @media screen and (max-width: 1188px) {
    margin-inline: 0;
  }
`;

const StyledWelcomeCopy = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 4rem 12rem;
  margin: 0 auto;
  max-width: 30%;

  @media screen and (max-width: 1188px) {
    max-width: 500px;
    padding: 2rem;
  }
`

const StyledH1 = styled.h1`
  text-align: center;
`;

const StyledTNH = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 700px;
`;

const StyledImageArea = styled.div`
  float: left;

  @media screen and (max-width: 1188px) {
    text-align: center;
    width: 100%;
  }
`

const StyledA = styled.a`
  text-decoration: none;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Home = () => {
  return (
    <StyledWelcome>
      <StyledImageArea>
        <StyledTNH
          src="https://blue-heron-public-images.s3.amazonaws.com/TNH_Retreat_YMCA_RMNP_2011.jpeg"
          alt="Thich Nhat Hanh"
        />
      </StyledImageArea>
      <StyledWelcomeCopy>
        <StyledH1>Welcome, dear friend</StyledH1>
        <p>
          Blue Heron Sangha is a meditation community in the tradition of the late Vietnamese
          Zen Master Thich Nhat Hanh, who taught and led retreats in the West for over
          40 years. He teaches mindfulness to reduce suffering in individuals, families,
          and society.
        </p>
        <p>
            The sangha meets virtually on Tuesday evenings at 7:15pm US Eastern
            Time via Zoom. We also meet on the first Saturday of each month for
            a morning of mindfulness.
          </p>
          <p>
            If you are interested in joining our practice, please contact us via{" "}
            <StyledA href="mailto:scoen@columbus.rr.com?subject=Interest in Blue Heron Sangha">
              email
            </StyledA>
            . No experience is required. Just <StyledLink to="/about">begin</StyledLink>!
          </p>
      </StyledWelcomeCopy>
    </StyledWelcome>
  );
};

export default Home;
