import React, { useState } from "react";
import menu_icon from "../../images/menu_icon.png";
import { NavLinks } from "..";

const MobileNav = () => {
  const [displayMenu, setDisplayMenu] = useState(false);

  return (
    <>
      <img
        src={menu_icon}
        alt="menu"
        onClick={() => setDisplayMenu(!displayMenu)}
      />
      {displayMenu && <NavLinks setDisplayMenu={setDisplayMenu} />}
    </>
  );
};

export default MobileNav;
