import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Header, Footer } from "./components";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div role="main">
          <AppRoutes />
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
