import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledUl = styled.ul`
  display: flex;
  padding: 0;

  @media screen and (max-width: 1188px) {
    display: block;
    position: absolute;
    top: 112px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: hsl(0deg 4% 85%);
  }
`;

const StyledLi = styled.li`
  list-style: none;
  margin: 0 1rem;
  font-size: 24px;

  @media screen and (max-width: 1188px) {
    text-align: center;
    margin: 0;
    padding: 1.5rem 0;

    &:hover {
      background-color: #eee;
    }
  }
`;

const StyledInternalLink = styled(Link)`
  text-decoration: none;
  padding-right: 20px;
  color: white;

  @media screen and (max-width: 1188px) {
    color: black;
  }
`;

const NavLinks = ({ setDisplayMenu }) => {
  return (
    <StyledUl onClick={() => setDisplayMenu(false)}>
      <StyledLi>
        <StyledInternalLink to="/">Home</StyledInternalLink>
      </StyledLi>
      <StyledLi>
        <StyledInternalLink to="/about">About</StyledInternalLink>
      </StyledLi>
      <StyledLi>
        <StyledInternalLink to="/meditation">Meditation</StyledInternalLink>
      </StyledLi>
      <StyledLi>
        <StyledInternalLink to="/teachers">Our Teachers</StyledInternalLink>
      </StyledLi>
      <StyledLi>
        <StyledInternalLink to="/resources">Resources for Mindful Living</StyledInternalLink>
      </StyledLi>
    </StyledUl>
  );
};

export default NavLinks;
