import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home, About, Meditation, OurTeachers, Resources } from "./pages";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/meditation" element={<Meditation />} />
      <Route path="/teachers" element={<OurTeachers />} />
      <Route path="/resources" element={<Resources />} />
    </Routes>
  );
};

export default AppRoutes;
