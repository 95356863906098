export const resourceLinks = [
  {
    label: "Plum Village",
    url: "https://plumvillage.org",
  },
  {
    label: "The Plum Village App",
    url: "https://plumvillage.app",
  },
  {
    label: "Thich Nhat Hanh Foundation",
    url: "https://thichnhathanhfoundation.org",
  },
  {
    label: "The Lotus Institute",
    url: "https://www.thelotusinstitute.org",
  },
  {
    label: "Find a sangha",
    url: "https://www.parallax.org/mindfulnessbell/sangha-directory",
  },
  {
    label: "The Five Mindfulness Trainings",
    url: "https://plumvillage.org/mindfulness/the-5-mindfulness-trainings",
  },
  {
    label: "10 Mindful Movements",
    url: "https://plumvillagesanghas.org/wp-content/uploads/2022/02/FacilitatorHandbook.pdf",
  },
];
