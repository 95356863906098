import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  border-top: 1px solid grey;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
  padding: 1rem;

  @media screen and (max-width: 1188px) {
    flex-direction: column;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <div>Images courtesy of Blue Heron Sangha members</div>
      <div>© {new Date().getFullYear()}</div>
      <div>
        Site maintained by{" "}
        <StyledLink href="https://github.com/dansajner">Dan Sajner</StyledLink>
      </div>
    </StyledFooter>
  );
};

export default Footer;
