import React from "react"
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import bhs_calligraphy_circle from "../../images/bhs_calligraphy_circle.png"
import { NavLinks, MobileNav } from ".."

const Navigation = styled.nav`
  width: 100%;
  display: flex;
  // justify-content: flex-end;
  justify-content: flex-start;

  @media screen and (max-width: 1188px) {
    justify-content: flex-end;
  }
`

const FullScreenNav = styled.div`
  @media screen and (max-width: 1188px) {
    display: none;
  }
`

const MobileNavWrapper = styled.div`
  display: none;

  @media screen and (max-width: 1188px) {
    display: block;
  }
`

const Header = () => {
  return (
    <header className="App-header">
      <Link to="/">
        <img src={bhs_calligraphy_circle} className="App-logo" alt="logo" />
      </Link>
      <Navigation>
        <FullScreenNav>
          <NavLinks />
        </FullScreenNav>
        <MobileNavWrapper>
          <MobileNav />
        </MobileNavWrapper>
      </Navigation>
    </header>
  );
};

export default Header;
