import React from "react";
import styled from "styled-components";

const AboutContainer = styled.div`
  margin-inline: 5% 5%;

  @media screen and (max-width: 1188px) {
    margin-inline: 0;
  }
`

const StyledMessage = styled.div`
  display: table;
  border-bottom: 1px solid grey;

  @media screen and (max-width: 1188px) {
    margin: auto;
  }
`;

const StyledLeftCopy = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 4rem 8rem;
  margin: 0 auto;
  max-width: 30%;

  @media screen and (max-width: 1188px) {
    max-width: 500px;
  }

  @media screen and (max-width: 400px) {
    padding: 4rem 2rem;
  }
`;

const StyledRightCopy = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 4rem 12rem;
  margin: 0 auto;
  max-width: 30%;

  @media screen and (max-width: 1188px) {
    max-width: 500px;
    padding: 4rem 4rem 4rem 4rem;
  }

  @media screen and (max-width: 400px) {
    padding: 4rem 2rem;
  }
`;

const StyledH1 = styled.h1`
  text-align: center;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 700px;
`;

const StyledRightImageArea = styled.div`
  float: right;

  @media screen and (max-width: 1188px) {
    text-align: center;
    width: 100%;
  }
`;

const StyledLeftImageArea = styled.div`
  float: left;

  @media screen and (max-width: 1188px) {
    text-align: center;
    width: 100%;
  }
`;

const About = () => {
  return (
    <AboutContainer>
      <StyledMessage>
        <StyledLeftCopy>
          <StyledH1>Meditate with us</StyledH1>
          <p>
            The sangha meets virtually on Tuesday evenings at 7:15pm US Eastern
            Time via Zoom and on the first Saturday of each month for
            a morning of mindfulness.
          </p>
        </StyledLeftCopy>
        <StyledRightImageArea>
          <StyledImage
            src="https://blue-heron-public-images.s3.amazonaws.com/herons/single_heron_standing.jpg"
            alt="Blue heron"
          />
        </StyledRightImageArea>
      </StyledMessage>
      <StyledMessage>
        <StyledLeftImageArea>
          <StyledImage
            src="https://blue-heron-public-images.s3.amazonaws.com/herons/heron_in_algae.jpg"
            alt="Blue heron standing in algae"
          />
        </StyledLeftImageArea>
        <StyledRightCopy>
          <StyledH1>Try it and see for yourself</StyledH1>
          <p>
            To meditate does not mean you give up other spiritual roots. The
            people who sit with Blue Heron are active in many faith traditions.
            We meditate to develop the energy of mindfulness, the energy of
            being present, aware and awake, as life is happening.
          </p>
        </StyledRightCopy>
      </StyledMessage>
      <StyledMessage>
        <StyledLeftImageArea>
          <StyledImage
            src="https://blue-heron-public-images.s3.amazonaws.com/herons/two_herons.jpg"
            alt="Blue heron standing in algae"
          />
        </StyledLeftImageArea>
        <StyledRightCopy>
          <StyledH1>What happens?</StyledH1>
          <p>We sit during different activities, done mostly in silence:</p>
          <ul>
            <li>
              Welcome and opening verse, inviting us to bring our minds into
              meditation
            </li>
            <li>
              Guided meditation, a series of gentle prompts about mindfulness
            </li>
            <li>
              Walking meditation, slowly walking, feet connecting with the
              ground, offers a stretch between sitting segments
            </li>
            <li>Silent sitting meditation, following the breath, in and out</li>
            <li>
              Singing, a brief song that embodies a principle of mindfulness
            </li>
            <li>Activity such as a recorded talk or a shared reading</li>
            <li>
              Sharing from the heart, a time for speaking from the heart,
              listening with compassion and kindness
            </li>
            <li>Closing verse</li>
            <li>Announcements, notices about upcoming events and news</li>
          </ul>
          <p>
            We also practice mindful movement, deep relaxation, and recite the
            Mindfulness Trainings, guides to conscious ethical living.
          </p>
        </StyledRightCopy>
      </StyledMessage>
      <StyledMessage>
        <StyledLeftCopy>
          <StyledH1>What is mindfulness meditation?</StyledH1>
          <p>
            In meditation we develop the energy of mindfulness, the energy of
            being present, aware and awake, as life is happening. We learn to
            pause, to focus our attention (starting with the breath), to
            maintain that focus for a period of time, to begin anew when
            distractions arise, and not to judge ourselves when (not if!) we
            forget.
          </p>
          <p>
            We learn to be mindful of our body—its sensations,
            appearance, and movements. We learn to be mindful of our mind—its
            stream of thoughts, emotions, perceptions, and concepts. With
            practice, over time, we learn to bring mindful awareness into
            everyday activities--walking, driving, conversation, shopping,
            washing the dishes--so that we can be peaceful and fully present to
            all we do.
          </p>
          <p>
            With mindfulness, we learn to see and cultivate
            conditions of happiness, and we learn to recognize and transform
            factors that contribute to our suffering. Mindful meditation teaches
            us to see the interconnection we share with each other, with Mother
            Earth, and with all beings.
          </p>
        </StyledLeftCopy>
        <StyledRightImageArea>
          <StyledImage
            src="https://blue-heron-public-images.s3.amazonaws.com/herons/heron_with_fish.jpg"
            alt="Blue heron"
          />
        </StyledRightImageArea>
      </StyledMessage>
    </AboutContainer>
  );
};

export default About;
