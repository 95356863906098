import React from "react";
import styled from "styled-components";

const StyledTNH = styled.img`
  float: left;
  max-width: 40%;
  height: auto;
  margin-right: 24px;

  @media screen and (max-width: 800px) {
    max-width: 100%;
    margin-block-end: 2rem;
  }
`;

const StyledImage = styled.img`
  height: auto;

  @media screen and (max-width: 800px) {
    max-width: 100%;
    margin-block-end: 2rem;
  }
`;

const StyledCopy = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 1000px;
  margin: 0 auto;
  max-width: 80%;
`;

const StyledHeading = styled.div`
  text-align: center;
  padding: 4rem 4rem 0 4rem;

  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem 18rem;

  @media screen and (max-width: 1188px) {
    max-width: 500px;
    padding: 1rem;
  }
`;

const StyledBlock = styled.div`
  display: table;
`;

const OurTeachers = () => {
  return (
    <StyledCopy>
      <StyledHeading>
        <h1>Thich Nhat Hanh</h1>
      </StyledHeading>
      <StyledSection>
        <StyledBlock>
          <StyledTNH
            src="https://blue-heron-public-images.s3.amazonaws.com/thn.jpeg"
            alt="picture of TNH"
          />
          <div>
            <p>
              Thich Nhat Hanh passed away on January 22, 2022, but his life and
              his teachings continue in all of us.
            </p>
            <p>
              Please enjoy this short biography of our teacher,{" "}
              <a
                href="https://blue-heron-public-images.s3.amazonaws.com/Thay+biography+1.pdf"
                target="blank"
              >
                Thich Nhat Hanh
              </a>
              , written by Blue Heron Sangha member, Sandy.
            </p>
            <p>
              For Thay&apos;s full biography please visit the wonderful{" "}
              <a
                href="https://plumvillage.org/thich-nhat-hanh/biography/thich-nhat-hanh-full-biography/"
                target="blank"
              >
                Plum Village
              </a>{" "}
              website.
            </p>
          </div>
        </StyledBlock>
      </StyledSection>
      <StyledHeading>
        <h1>About Peggy Rowe-Ward and Larry Ward</h1>
      </StyledHeading>
      <StyledSection>
        <StyledImage
            src="https://blue-heron-public-images.s3.amazonaws.com/peggy_larry.jpg"
            alt="picture of Peggy and Larry"
          />
        <p>
          Larry and Peggy helped form the Blue Heron Sangha in the spring of
          2006. They founded the Lotus Institute in 1996 to spread the teachings
          of mindfulness inspired by Thich Nhat Hanh&apos;s teachings.
        </p>
        <p>
          <b>Peggy&apos;s joyful spirit</b> enables her students to discover and
          embody their most creative and authentic selves. She offers a path of
          deep insight through methods such as movement, writing, art and
          ritual.
        </p>
        <p>
          Peggy has her EdD in Adult Education and her M.A. in Counseling
          Psychology. She is co-author with Larry of{" "}
          <u>Love&apos;s Garden: A Guide To Mindful Relationships</u>. She has
          taught in graduate schools of social work, psychology and counseling
          psychology. Her Doctoral research is in dreamwork. She has had a
          private therapy practice for many years and now offers consultations
          with a spiritual direction focus.
        </p>
        <p>
          <b>Larry is a senior teacher</b> in Buddhist Zen Master Thich Nhat
          Hanh&apos;s Plum Village tradition, the author of{" "}
          <u>America&apos;s Racial Karma</u>, and co- author of{" "}
          <u>Love&apos;s Garden: A Guide To Mindful Relationships.</u>
        </p>
        <p>
          He brings forty years of international experience in organizational
          change and local community renewal to his work at the Lotus Institute.
          He holds a PhD in Religious Studies with an emphasis on Buddhism and
          the neuroscience of meditation, and has trained at the Trauma Resource
          Institute. Dr. Ward has taught courses at Claremont Graduate
          University and the University of the West. As a teacher, Dr. Ward
          interweaves insights with personal stories and resounding clarity that
          express his Dharma name, "True Great Sound."
        </p>
        <p>
          <a href="https://www.thelotusinstitute.org/" target="blank">
            <b>The Lotus Institute</b>
          </a>{" "}
          is an educational non-profit that offers teachings, practice and
          guidance to nurture a healthy and resilient body and mind, blending
          Buddhist practices with trauma-informed teachings and research in
          neuroscience and psychology. This transformative work also involves
          play and curiosity.
        </p>
        <p>
          The Lotus Institute was founded with the support and guidance of Thich
          Nhat Hanh—Zen Master, poet, activist, and founder of the Plum Village
          tradition. The popularization and accessibility of "mindfulness" as we
          know it today would not have been possible without the work of Thich
          Nhat Hanh. With monasteries across the globe, his students are
          spreading his simple yet profound methods of teaching mindfulness and
          living fully in the present moment.
        </p>
      </StyledSection>
    </StyledCopy>
  );
};

export default OurTeachers;
