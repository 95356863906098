import React from "react";
import styled from "styled-components";
import { resourceLinks } from "./data/resourceLinks";

const ResourcesContainer = styled.div`
  margin-inline: 5% 5%;

  @media screen and (max-width: 1188px) {
    margin-inline: 0;
  }
`

const StyledMessage = styled.div`
  display: table;
  width: 100%;
  border-bottom: 1px solid grey;
  min-height: 1500px;

  @media screen and (max-width: 1188px) {
    margin: auto;
  }
`;

const StyledLeftCopy = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 4rem 12rem;
  margin: 0 auto;

  @media screen and (max-width: 1188px) {
    max-width: 500px;
    padding: 4rem 8rem;
  }

  @media screen and (max-width: 400px) {
    padding: 2rem 2rem;
  }
`;

const StyledRightImageArea = styled.div`
  float: right;

  @media screen and (max-width: 1188px) {
    text-align: center;
    width: 100%;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 700px;
`;


const StyledHeading = styled.div`
  text-align: center;
  padding: 4rem 4rem 2rem 4rem;
`;


const StyledTd = styled.td`
  padding: 1rem;
`;

const Resources = () => {
  return (
    <ResourcesContainer>
      <StyledHeading>
        <h2>Some helpful links to guide your path</h2>
      </StyledHeading>
      <StyledMessage>
        <StyledLeftCopy>
          <table>
            {resourceLinks.map((link) => (
              <tr>
                <StyledTd>
                  <a href={link.url} target="blank">
                    {link.label}
                  </a>
                </StyledTd>
              </tr>
            ))}
          </table>
        </StyledLeftCopy>
        <StyledRightImageArea>
          <StyledImage
            src="https://blue-heron-public-images.s3.amazonaws.com/lotus_frog.jpg"
            alt="Pink lotus and frog"
          />
        </StyledRightImageArea>
      </StyledMessage>
    </ResourcesContainer>
  );
};

export default Resources;
